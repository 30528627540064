import { reactive, ref, toRefs } from 'vue';
type FormData = {
  addressCity: string;
  addressDetail: string;
  addressDistrict: string;
  addressProvince: string;
  areaId: string | number;
  cityId: string | number;
  contactsEmail: string;
  contactsName: string;
  contactsPosition: string;
  contactsSex: number;
  contactsTelephone: string | number;
  contactsWeixin: string;
  attachmentVo: Record<string, unknown> | null;
  fullName: string;
  id: string | number;
  fileNameAndUrlDtoList: any[];
  storageFileList: any[];
  attachmentDto: Record<string, unknown> | null;
  portName: string;
  provinceId: string | number;
  remark: string;
  shortName: string;
  version: string | number;
  [propName: string]: any;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    addressCity: '',
    addressDetail: '',
    addressDistrict: '',
    addressProvince: '',
    areaId: null,
    cityId: null,
    contactsEmail: '',
    contactsName: '',
    contactsPosition: '',
    contactsSex: 1,
    contactsTelephone: '',
    contactsWeixin: '',
    fullName: '',
    attachmentVo: null,
    storageFileList: null,
    id: null,
    fileNameAndUrlDtoList: [], // todo
    attachmentDto: null,
    portName: '',
    provinceId: null,
    remark: '',
    shortName: '',
    version: null,
    ...params,
  });
  const customFormData = reactive({
    contractUrlsArray: [], // 文件
    coldUrlsArray: [],
    fileTips: '允许类型：pdf,doc,docx,jpg,png,gif,bmp,zip   大小上限30M    只支持上传5个文件',
    coldFileTips: '允许类型：pdf,doc,docx   大小上限30M    此类文件最多可上传1个',
    portList: [],
    Options: {
      contactsSex: [
        { value: 1, label: '先生' },
        { value: 2, label: '女士' },
      ],
    },
  });
  const rules = reactive({
    shortName: [
      {
        required: true,
        message: '请输入冷库',
        trigger: 'blur',
      },
    ],
    fullName: [
      {
        required: true,
        message: '请输入冷库全称',
        trigger: 'blur',
      },
    ],
    contactsTelephone: [
      {
        required: true,
        message: '请输入联系电话',
        trigger: 'blur',
      },
    ],
    cityId: [{ required: true, message: '请选择冷库地址', trigger: 'change' }],
    addressDetail: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  const changeShortName = (e) => {
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z\d\u4E00-\u9FA5]/g, ''); // 清除中文英文以外的字符
    formData.shortName = value;
  };
  const changeChinese = (e, name) => {
    let value = e.target.value;
    value = value.replace(/[^\u4E00-\u9FA5]/g, ''); // 清除中文以外的字符
    formData[name] = value;
  };
  const changePhone = (e) => {
    let value = e.target.value;
    value = value.replace(/[^\d-]/g, ''); // 清除中文以外的字符
    formData.contactsTelephone = value;
  };
  return {
    rules,
    formData,
    formRef,
    setFormData,
    ...toRefs(customFormData),
    changeShortName,
    changeChinese,
    changePhone,
  };
};
