import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import { httpPost } from '@/api';
import { erpConfirm, successMessage, errorMessage } from '@/utils/publicMethods';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'shortName',
      value: '',
      placeholder: '请输入冷库',
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '冷库',
      prop: 'shortName',
      minWidth: 128,
    },
    {
      label: '冷库全称',
      prop: 'fullName',
      minWidth: 265,
    },
    {
      label: '地址',
      prop: 'address',
      minWidth: 532,
    },
    {
      label: '口岸',
      prop: 'portName',
      minWidth: 115,
    },
    {
      label: '联系电话',
      prop: 'contactsTelephone',
      minWidth: 161,
    },
    { label: '创建人', prop: 'creator', minWidth: 143 },
    { label: '创建时间', prop: 'createdTime', minWidth: 200 },
    {
      label: '操作',
      component: defineComponent({
        template: `
          <div class="crm-btn-groups">        
            <el-button v-permission="['crm:storage:operateStorage']" type="primary" plain @click="edit(row)">编辑</el-button>
            <el-button v-permission="['crm:storage:deleteStorage']" type="info" plain @click="deleteRow(row)">删除</el-button>
          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const edit = (row) => {
            Instance.setupState.rowData.value = row;
            Instance.setupState.setFormType(2);
            Instance.setupState.showVisible();
          };
          /** 删除 */
          const deleteRow = (row) => {
            Instance.setupState.rowData.value = row;
            erpConfirm('确定删除吗？')
              .then(async () => {
                const res = await httpPost('/malicrm/storage/deleteStorage', {
                  id: row.id,
                  version: row.version,
                });
                if (res.code === 200) {
                  successMessage('已删除');
                  Instance.setupState.refreshTable(true);
                } else {
                  errorMessage(res.message);
                }
              })
              .catch(() => {
                return false;
              });
          };
          return { edit, deleteRow };
        },
      }),
      prop: '',
      fixed: 'right',
      minWidth: 200,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
