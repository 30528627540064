<template>
  <CrmFormLayout title="查看冷库">
    <div class="view-storage-page">
      <div class="view__form">
        <div class="view__group">
          <label class="view__label">冷&nbsp;&nbsp;&nbsp;库</label>
          <div class="view__input">{{ detailData.shortName }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">冷库全称</label>
          <div class="view__input">
            <el-tooltip
              effect="dark"
              :content="detailData.fullName"
              placement="top-start"
              :disabled="detailData.fullName && detailData.fullName.length <= 30"
              class="tootip-over"
            >
              <div>{{ detailData.fullName }}</div>
            </el-tooltip>
          </div>
        </div>
        <div class="view__group">
          <label class="view__label">口岸</label>
          <div class="view__input">{{ detailData.portName }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">联系人</label>
          <div class="view__input">{{ detailData.contactsName }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">职位</label>
          <div class="view__input">{{ detailData.contactsPosition }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">联系电话</label>
          <div class="view__input">{{ detailData.contactsTelephone }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">邮箱</label>
          <div class="view__input">{{ detailData.contactsEmail }}</div>
        </div>
        <div class="view__group">
          <label class="view__label">微信</label>
          <div class="view__input">{{ detailData.contactsWeixin }}</div>
        </div>
      </div>
      <el-form>
        <div class="maili-edit__form label-con" style="margin-top: 20px">
          <el-form-item label="冷库地址">
            <div class="view__area">{{ detailData.address }}</div>
          </el-form-item>
        </div>
        <div class="maili-edit__form detail-contract-add-file">
          <el-form-item label="">
            <div class="file-upload-title">
              <div class="upload-title">合同文件</div>
            </div>
            <div class="contract-upload-wrap">
              <FileContent
                :readOnly="readOnly"
                :fromType="formType"
                fileType="crmStorageContract"
                :tips="fileTips"
                :fileList="contractUrlsArray"
                :max="5"
              ></FileContent>
            </div>
          </el-form-item>
        </div>
        <div class="maili-edit__form label-con" style="margin-top: 20px">
          <el-form-item label="冷库备注">
            <div class="view__area">{{ detailData.remark }}</div>
          </el-form-item>
        </div>
        <div class="maili-edit__form detail-contract-add-file" v-if="coldUrlsArray.length > 0">
          <el-form-item label="">
            <div class="file-upload-title">
              <div class="upload-title">冷库防疫措施说明</div>
            </div>
            <div class="contract-upload-wrap">
              <FileContent
                :readOnly="readOnly"
                :fromType="formType"
                fileType="crmStorageEpidemic"
                :tips="coldFileTips"
                :fileList="coldUrlsArray"
                :max="1"
              ></FileContent>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <div class="crm-button__group">
        <button class="crm-form__button primary" @click="close(false)">关闭</button>
      </div>
    </div>
  </CrmFormLayout>
</template>

<script>
import FileContent from '@/components/FileContent/FileContent.vue';
export default {
  components: { FileContent },
  props: ['rowData'],
  created() {
    this.getinitData(this.rowData.id);
  },
  data() {
    return {
      detailData: {
        addressCity: '',
        addressDetail: '',
        addressDistrict: '',
        addressProvince: '',
        areaId: 0,
        cityId: 0,
        contactsEmail: '',
        contactsName: '',
        contactsPosition: '',
        contactsSex: '',
        contactsSexName: '',
        contactsTelephone: '',
        contactsWeixin: '',
        storageFileList: [],
        createdTime: '',
        creator: '',
        fullName: '',
        id: 0,
        portName: '',
        provinceId: 0,
        remark: '',
        shortName: '',
        version: 0,
      },
      contractUrlsArray: [], // 文件
      coldUrlsArray: [],
      formType: 3,
      fileType: '.pdf, .doc, .docx, .jpg, .png, .gif, .bmp, .zip',
      fileTips: '允许类型：pdf,doc,docx,jpg,png,gif,bmp,zip   大小上限：30M',
    };
  },
  methods: {
    async getinitData(id) {
      this.ajax({
        type: 'GET',
        url: '/malicrm/storage/storageDetail/' + id,
        data: {},
        success: (res) => {
          if (res.code === 200) {
            if (res.result) {
              const data = res.result;
              for (const key in data) {
                if (data[key] === '' || data[key] === null) {
                  data[key] = '-';
                }
              }
              this.initFileFn(res.result);
              this.detailData = data;
            }
          }
        },
      });
    },
    initFileFn(res) {
      this.contractUrlsArray = [];
      this.coldUrlsArray = [];
      const { storageFileList, attachmentVo } = res;
      if (attachmentVo && attachmentVo.name) {
        this.coldUrlsArray.push(attachmentVo);
      }
      storageFileList.forEach((item) => {
        this.contractUrlsArray.push({ name: item.name, url: item.url });
      });
    },
    close() {
      this.$emit('close', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.view-storage-page {
  .view__form {
    padding: 24px 20px 4px 20px;
    border: 1px solid #e3e4e6;
    background: #fff;
    margin-bottom: 20px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .view__group {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .view__label {
      color: #1a1a1a;
      font-size: 14px;
      width: 60px;
      text-align: justify;
      line-height: 0;
      position: relative;
      top: 3px;

      &::after {
        content: '';
        display: inline-block;
        width: 100%;
      }
    }

    .view__input {
      margin-left: 19px;
      color: #1a1a1a;
      font-size: 14px;
      box-sizing: border-box;
      width: 360px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #e3e4e6;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: keep-all;
    }
  }

  .view__area {
    border: 1px solid #e3e4e6;
    border-radius: 2px;
    padding: 12px;
    color: #1a1a1a;
    font-size: 14px;
    display: inline-block;
    width: 100%;
    min-height: 60px;
    line-height: 1.5;
    white-space: pre-wrap;
    word-break: break-all;
    overflow: hidden;
  }
  .maili-edit__form {
    width: 966px;
    overflow: hidden;
    background: #fff;
    padding: 16px 20px 6px 20px;
    border: 1px solid #e3e4e6;

    .el-form-item__label {
      color: #303133;
      margin-top: -4px;
      position: relative;
      padding-bottom: 0 !important;
      line-height: 30px !important;
    }

    .el-input--small .el-input__inner {
      height: 40px;
      line-height: 40px;
      font-size: 14px !important;
      padding: 0 10px;
      color: #1a1a1a;
      border-radius: 2px;
    }

    .mali-full__input11 .el-textarea__inner {
      height: 60px;
      word-break: break-all;
    }

    .el-textarea__inner {
      padding: 5px 10px;
      color: #1a1a1a;
      height: 140px;
      border-radius: 2px;
    }

    .mali-full__input {
      width: 100% !important;
    }

    .el-select .el-input .el-select__caret {
      color: #333;
      font-size: 12px;
      line-height: 40px;
    }

    .el-select .el-input .el-select__caret.el-icon-circle-close {
      font-size: 14px;
      color: #c0c4cc;
    }
  }
  .detail-contract-add-file {
    margin-top: 20px;
  }
}
.file-upload-title {
  position: relative;
  padding-bottom: 18px;
  .upload-title {
    font-size: 16px;
    color: #333333;
    height: 18px;
    line-height: 18px;
    font-family: MicrosoftYaHei;
    span {
      display: inline-block;
      color: #ff6c2b;
      font-size: 22px;
      transform: translate(6px, 6px);
    }
  }
}
.file-upload-title ::after {
  position: absolute;
  width: 960px;
  left: -20px;
  top: 48px;
  content: '';
  height: 2px;
  font-size: 16px;
  border-bottom: 1px dashed rgba(227, 228, 230, 1);
}
.file-upload-title span::after {
  border: none;
}
</style>
