
import { httpGet, httpPost } from '@/api';
import CitySelect from '@/components/CitySelect/CitySelect.vue';
import FileContent from '@/components/FileContent/FileContent.vue';
import { ajaxLoading, errorMessage, setTitleLabel, successMessage } from '@/utils/publicMethods';
import { defineComponent, ref, computed } from 'vue';
import useFormData from './useStorageFormData';
export default defineComponent({
  components: { CitySelect, FileContent },
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const addressData = ref();
    const isInPool = ref(0);
    const params: any = {};
    if (props.formType === 2) {
      // params = Object.assign({ operateType: 2 }, props.data);
      // params.isInPool = params.isInPool ? 1 : 0;
      // let data = props.data as any;
      // 设置区域
      // addressData.value = [data?.provinceId, data.cityId, data.areaId];
    }
    const { rules, formData, formRef, setFormData, contractUrlsArray, changeShortName, portList, changeChinese, changePhone, coldUrlsArray } = useFormData(
      params
    );
    /** 获取文件信息 */
    const getFileUrls = (fileList: any[]) => {
      formData.fileNameAndUrlDtoList = [];
      fileList.forEach((item, index) => {
        formData.fileNameAndUrlDtoList.push({
          storageFileUrl: fileList[index].url,
          storageFileName: fileList[index].name,
        });
      });
    };
    const readOnly = computed(() => {
      return props.formType > 2;
    });
    // 获取省市区联动
    const getArea = (data) => {
      formData.addressProvince = data.province.name;
      formData.provinceId = data.province.id;
      formData.addressCity = data.city.name;
      formData.cityId = data.city.id;
      formData.addressDistrict = data.county.name;
      formData.areaId = data.county.id;
    };
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const initFile = () => {
      const { storageFileList, attachmentVo } = formData;
      if (attachmentVo) {
        formData.attachmentDto = attachmentVo;
        (coldUrlsArray.value as any).push(attachmentVo);
      }
      storageFileList.forEach((item) => {
        (contractUrlsArray.value as any).push({
          name: item.name,
          url: item.url,
        });
        formData.fileNameAndUrlDtoList.push({
          storageFileName: item.name,
          storageFileUrl: item.url,
        });
      });
    };
    const submitData = async () => {
      const params = {
        ...formData,
      };
      ajaxLoading.lock();
      const res = await httpPost('/malicrm/storage/operateStorage', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        successMessage('保存成功');
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          // formData.signDate = formatTime(formData.signDate, 'YYYY-MM-DD');
          submitData();
        } else {
          return false;
        }
      });
    };
    const getPortName = async (name) => {
      const params = {
        portName: name,
      };
      const res = await httpPost('/malicrm/port/queryByName', params);
      if (res.code === 200) {
        if (res.result) {
          portList.value = res.result;
        } else {
          portList.value = [];
        }
      } else {
        errorMessage(res.message);
      }
    };
    const initPortName = () => {
      if (portList.value && portList.value.length <= 0) {
        getPortName('');
      }
    };
    const getColdFileUrls = (fileList: any[]) => {
      if (fileList.length > 0) {
        formData.attachmentDto = {
          name: fileList[0].name,
          url: fileList[0].url,
        };
      } else {
        formData.attachmentDto = null;
      }
    };
    // 初始化数据
    const initData = async (id) => {
      if (!id) return;
      const res = await httpGet(`/malicrm/storage/storageDetail/${id}`);
      if (res.code === 200) {
        setFormData(res.result);
        addressData.value = [res.result?.provinceId, res.result.cityId, res.result.areaId];
        initFile();
      } else {
        errorMessage(res.message);
      }
    };
    getPortName('');
    if (props.formType > 1) {
      // const feeObj = JSON.parse(JSON.stringify(props.data));
      // setFormData(feeObj.value);
      // formRef.value.validateField('settleType');
      // let data = (props.data as any).value;
      // let id = data.id;
      console.log('(props.data as any).id', (props.data as any).id);
      initData((props.data as any).id);
    }
    return {
      close,
      formData,
      getArea,
      addressData,
      saveFormData,
      setFormData,
      rules,
      formRef,
      setTitleLabel,
      isInPool,
      getFileUrls,
      contractUrlsArray,
      initFile,
      initData,
      readOnly,
      changeShortName,
      getPortName,
      portList,
      initPortName,
      changeChinese,
      changePhone,
      getColdFileUrls,
      coldUrlsArray,
    };
  },
});
